import React from 'react'
import WrapRootElement from './src/wrap-root-element' 
import WrapPageElement from './src/wrap-page-element'
import createEmotionCache from './src/utils/createEmotionCache'
import "core-js/stable"

// Import global CSS
import '@fortawesome/fontawesome-free/css/all.min.css'
import './src/components/common/layout.css'
import './src/styles/globalHtmlClasses.scss'

// Import fonts
import '@fontsource/poppins/200.css'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/700.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/ibm-plex-sans/300.css'
import '@fontsource/ibm-plex-sans/400.css'
import '@fontsource/ibm-plex-sans/500.css'
import '@fontsource/ibm-plex-sans/700.css'

// Import third-party CSS
import 'react-responsive-carousel/lib/styles/carousel.min.css'

// Create a client-side Emotion cache
const clientSideEmotionCache = createEmotionCache()

// Export wrapRootElement with the client-side cache
export const wrapRootElement = ({ element }) => (
  <WrapRootElement element={element} emotionCache={clientSideEmotionCache} />
)

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = 'manual'
  const currentPosition = getSavedScrollPosition(location, location.key)
  const [x, y] = currentPosition || [0, 0]

  const performScroll = () => {
    window.requestAnimationFrame(() => {
      window.scrollTo(x, y)
    })
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        window.scrollTo(x, y)
      })
    }, 25) 
  }

  performScroll()
  return false 
}
export const wrapPageElement = ({ element, props }) => (
  <WrapPageElement element={element} props={props} />
)
