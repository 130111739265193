import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import React from 'react'
import FormField from './FormField'

const FormRenderer = ({
  formFields,
  formValues,
  handleInputChange,
  fieldErrors,
  fieldRefs,
  formHTML,
  formStyles,
}) => {
  if (formFields && formFields.length > 0) {
    return (
      <>
        {formFields.map((field, index) => (
          <FormField
            key={index}
            field={field}
            formValues={formValues}
            handleInputChange={handleInputChange}
            fieldErrors={fieldErrors}
            fieldRefs={fieldRefs}
            formStyles={formStyles}
          />
        ))}
      </>
    )
  } else if (formHTML) {
    const transformFunction = (node, index) => {
      if (node.type === 'tag') {
        const { name } = node
        if (['input', 'select', 'textarea', 'label', 'button'].includes(name)) {
          return (
            <FormField
              key={index}
              node={node}
              formValues={formValues}
              handleInputChange={handleInputChange}
              fieldErrors={fieldErrors}
              fieldRefs={fieldRefs}
              formStyles={formStyles}
            />
          )
        }
      }
      return undefined
    }

    return <>{parse(formHTML, { replace: transformFunction })}</>
  }
  return null
}

FormRenderer.propTypes = {
  formFields: PropTypes.array,
  formValues: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  fieldRefs: PropTypes.object.isRequired,
  formHTML: PropTypes.string,
  formStyles: PropTypes.any,
}

export default FormRenderer
