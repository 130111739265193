import { styled } from '@mui/material/styles'
import { domToReact } from 'html-react-parser'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'


const errorStyle = { color: 'red', display: 'block', marginTop: '0.25rem' }
const fieldContainerStyle = { marginBottom: '1rem' }
const labelStyle = { display: 'block', marginBottom: '0.5rem' }
const buttonStyle = { marginTop: '1rem' }

const transformAttributes = (attribs) => {
  const transformed = {}
  Object.keys(attribs).forEach((key) => {
    let newKey = key
    if (key === 'class') newKey = 'className'
    if (key === 'for') newKey = 'htmlFor'
    if (key === 'autocomplete') newKey = 'autoComplete'
    transformed[newKey] = attribs[key]
  })
  return transformed
}

const StyledFieldContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'formStyles',
})(({ formStyles }) => ({
  ...formStyles,
}))

const FormField = ({
  field,
  node,
  formValues,
  handleInputChange,
  fieldErrors,
  fieldRefs,
  validationRules,
  formStyles,
}) => {

  const commonPropsField = useMemo(() => {
    if (!field) return {}
    const { id } = field.props
    return {
      id,
      name: id,
      value: formValues[id] || '',
      onChange: handleInputChange,
      'aria-invalid': fieldErrors[id] ? 'true' : 'false',
      'aria-describedby': fieldErrors[id] ? `${id}-error` : undefined,
      ref: (el) => {
        if (el) fieldRefs.current[id] = el
      },
    }
  }, [field, formValues, handleInputChange, fieldErrors, fieldRefs])

  const commonPropsNode = useMemo(() => {
    if (!node) return {}
    const { id } = transformAttributes(node.attribs)
    return {
      key: node.key,
      className: transformAttributes(node.attribs).className || '',
      'aria-invalid': fieldErrors[id] ? 'true' : 'false',
      'aria-describedby': fieldErrors[id] ? `${id}-error` : undefined,
      ref: (el) => {
        if (el) fieldRefs.current[id] = el
      },
      ...transformAttributes(node.attribs),
      onChange: handleInputChange,
      value: formValues[id] || '',
    }
  }, [node, handleInputChange, formValues, fieldErrors, fieldRefs])

  if (!field && !node) return null

  if (field) {
    const { type, props } = field
    const { id, label, options, ...inputProps } = props

    return (
      <StyledFieldContainer formStyles={formStyles}>
        {type === 'input' && (
          <div style={fieldContainerStyle}>
            {label && (
              <label htmlFor={id} style={labelStyle}>
                {label}
              </label>
            )}
            <input {...commonPropsField} {...inputProps} />
            {fieldErrors[id] && (
              <span id={`${id}-error`} style={errorStyle}>
                {fieldErrors[id]}
              </span>
            )}
          </div>
        )}

        {type === 'select' && (
          <div style={fieldContainerStyle}>
            {label && (
              <label htmlFor={id} style={labelStyle}>
                {label}
              </label>
            )}
            <select {...commonPropsField} {...inputProps}>
              {options?.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
            {fieldErrors[id] && (
              <span id={`${id}-error`} style={errorStyle}>
                {fieldErrors[id]}
              </span>
            )}
          </div>
        )}

        {type === 'textarea' && (
          <div style={fieldContainerStyle}>
            {label && (
              <label htmlFor={id} style={labelStyle}>
                {label}
              </label>
            )}
            <textarea {...commonPropsField} {...inputProps} />
            {fieldErrors[id] && (
              <span id={`${id}-error`} style={errorStyle}>
                {fieldErrors[id]}
              </span>
            )}
          </div>
        )}

        {type === 'button' && (
          <button {...commonPropsField} {...inputProps} style={buttonStyle}>
            {label}
          </button>
        )}
      </StyledFieldContainer>
    )
  }

  const { name, children } = node

  return (
    <StyledFieldContainer formStyles={formStyles}>
      {name === 'input' && (() => {
        const { type = 'text' } = commonPropsNode
        const marginBottomValue =
          type === 'hidden' ? { marginBottom: '0rem' } : { marginBottom: '1rem' }

        return (
          <div key={node.key} style={marginBottomValue}>
            <input {...commonPropsNode} />
            {fieldErrors[commonPropsNode.id] && (
              <span id={`${commonPropsNode.id}-error`} style={errorStyle}>
                {fieldErrors[commonPropsNode.id]}
              </span>
            )}
          </div>
        )
      })()}

      {name === 'select' && (
        <div key={node.key} style={fieldContainerStyle}>
          <select {...commonPropsNode}>
            {domToReact(children)}
          </select>
          {fieldErrors[commonPropsNode.id] && (
            <span
              id={`${commonPropsNode.id}-error`}
              style={errorStyle}
            >
              {fieldErrors[commonPropsNode.id]}
            </span>
          )}
        </div>
      )}

      {name === 'textarea' && (
        <div key={node.key} style={fieldContainerStyle}>
          <textarea {...commonPropsNode}>
            {domToReact(children)}
          </textarea>
          {fieldErrors[commonPropsNode.id] && (
            <span
              id={`${commonPropsNode.id}-error`}
              style={errorStyle}
            >
              {fieldErrors[commonPropsNode.id]}
            </span>
          )}
        </div>
      )}

      {name === 'label' && (
        <label
          key={node.key}
          {...commonPropsNode}
          htmlFor={commonPropsNode.htmlFor || name}
          style={labelStyle}
        >
          {domToReact(children)}
        </label>
      )}

      {name === 'button' && (
        <button
          key={node.key}
          {...commonPropsNode}
          type={commonPropsNode.type || 'button'}
          style={buttonStyle}
        >
          {domToReact(children)}
        </button>
      )}
    </StyledFieldContainer>
  )
}

FormField.propTypes = {
  field: PropTypes.object,
  node: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  fieldRefs: PropTypes.object.isRequired,
  validationRules: PropTypes.object,
  formStyles: PropTypes.object,
}

export default React.memo(FormField)
