export const sanitizeConfig = {
  ADD_TAGS: ['link', 'style', 'label', 'input', 'button', 'textarea', 'div'],
  ADD_ATTR: [
    'id',
    'src',
    'href',
    'type',
    'rel',
    'style',
    'async',
    'defer',
    'data-contentid',
    'data-*',
    'name',
    'title',
  ],
  WHOLE_DOCUMENT: true,
  KEEP_CONTENT: false,
  FORCE_BODY: false,
}

export const removeFARecaptchaHook = (node) => {
  if (!node) {
    return
  }
  const tagName = node.tagName?.toLowerCase()

  const safeRemove = (childNode) => {
    if (childNode && childNode.parentNode) {
      childNode.parentNode.removeChild(childNode)
    }
  }

  if (tagName === 'form') {
    const parent = node.parentNode
    if (parent) {
      while (node.firstChild) {
        parent.insertBefore(node.firstChild, node)
      }
      safeRemove(node)
    }
    return
  }

  if (
    tagName === 'div' &&
    (node.id === 'google-captcha' ||
      node.classList.contains('g-recaptcha') ||
      node.classList.contains('grecaptcha-badge'))
  ) {
    safeRemove(node)
    return
  }

  if (tagName === 'iframe' && node.src.includes('recaptcha')) {
    safeRemove(node)
    return
  }

  if (tagName === 'script' && node.src.includes('recaptcha')) {
    safeRemove(node)
    return
  }

  if (tagName === 'noscript' && node.innerHTML.includes('recaptcha')) {
    safeRemove(node)
    return
  }

  if (tagName === 'div' && node.hasAttribute('dir')) {
    const child = node.querySelector('.actions')
    safeRemove(child)
  }
}
