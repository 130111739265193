module.exports = {
  /***********************************************
   * Font Families
   ***********************************************/
  fontFamily: {
    heading: 'Poppins, sans-serif',  // for H1-H6
    body: 'Roboto, sans-serif',      // default for body text
    label: 'Poppins, sans-serif',    // for buttons/labels
    code: 'Inconsolata, monospace',  // code blocks
    link: 'Roboto, sans-serif',      // anchor links
    icon: 'Font Awesome 5 Pro',      // icon font

    '@media (min-width:600px)': {
      heading: 'Poppins, sans-serif',
      body: 'Roboto, sans-serif',
      label: 'Poppins, sans-serif',
      code: 'Inconsolata, monospace',
      link: 'Roboto, sans-serif',
      icon: 'Font Awesome 5 Pro',
    },
  },

  /***********************************************
   * Font Weights
   ***********************************************/
  fontWeights: {
    // Headings
    headingLight: 300,
    headingRegular: 400,
    headingMedium: 500,
    headingBold: 700,

    // Body
    bodyLight: 300,
    bodyRegular: 400,
    bodyMedium: 500,
    bodyBold: 700,

    // Buttons / Labels
    buttonSemiBold: 600,
    labelRegular: 400,
    labelBold: 700,

    // Code
    codeRegular: 400,
    codeBold: 700,

    // Icons
    iconLight: 300,
    iconRegular: 400,
    iconSolid: 500,
    iconDefault: 300,
  },

  /***********************************************
   * Font Sizes
   ***********************************************
   * A simpler, well-spaced scale:
   *   xs: 12px
   *   sm: 14px
   *   md: 16px
   *   lg: 20px
   *   xl: 24px
   *   2xl: 32px
   *   3xl: 40px
   *   4xl: 48px
   ***********************************************/
  fontSizes: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 20,
    xl: 24,
    '2xl': 32,
    '3xl': 40,
    '4xl': 48,

    // Icon-specific sizes (unchanged from your legacy code)
    icon3xl: 360,
    icon2xl: 180,
    iconXl: 48,
    iconLg: 32,
    iconMd: 24,
    iconSm: 16,
    iconXs: 12,
  },

  /***********************************************
   * Line Heights
   ***********************************************
   * Use numeric multipliers for typical text:
   *   tight: 1.2
   *   snug: 1.3
   *   normal: 1.5
   *   relaxed: 1.6
   ***********************************************/
  lineHeights: {
    tight: 1.2,
    snug: 1.3,
    normal: 1.5,
    relaxed: 1.6,

    // For icons, if you want pixel-based line-heights:
    icon3xl: '360px',
    icon2xl: '180px',
    iconXl: '48px',
    iconLg: '32px',
    iconMd: '24px',
    iconSm: '16px',
    iconXs: '12px',
  },

  /***********************************************
   * Letter Spacing
   ***********************************************
   * Simplify into a small set of typical increments
   ***********************************************/
  letterSpacing: {
    tighter: -0.5,
    none: 0,
    normal: 0.25,
    wide: 0.4,
    wider: 1,
  },

  /***********************************************
   * Paragraph Spacing
   ***********************************************
   * Typically, margin/padding is handled in CSS,
   * but if you want to store in the theme:
   ***********************************************/
  paragraphSpacing: {
    none: 0,
    sm: 4,
    md: 8,
    lg: 16,
  },
}
