import React, { useContext, createContext, useEffect, useState, useMemo } from 'react'
import { useLocation } from '@gatsbyjs/reach-router' 
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'

import { COMMON_MOBILE_BREAKPOINT, USER_EXPERIENCES } from '../constants'
import { useAuthContext } from './AuthContext'

const ContactUsCollapseContext = createContext()

const useContactUsCollapseContext = () => {
  const context = useContext(ContactUsCollapseContext)
  if (!context) {
    // Provide default values to prevent destructuring errors
    return [false, () => {}]
  }
  return context
}

const ContactUsCollapseContexProvider = ({ children }) => {
  const isMobile = useMediaQuery(COMMON_MOBILE_BREAKPOINT)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { pathname } = useLocation()

  const auth = useAuthContext()

  useEffect(() => {
    if (
      isMobile ||
      auth?.state?.userExperience === USER_EXPERIENCES.INSTITUTIONAL_ONLY
    ) {
      setIsCollapsed(true)
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('contactUsCollapse', true)
      }
    } else if (typeof window !== 'undefined') {
      const sessionStorageIsCollapsed =
        window.sessionStorage.getItem('contactUsCollapse')

      if (sessionStorageIsCollapsed) {
        setIsCollapsed(sessionStorageIsCollapsed === 'true')
      }
    }
  }, [isMobile, auth, pathname])

  const handleSetIsCollapsed = () => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('contactUsCollapse', !isCollapsed)
    }

    setIsCollapsed(!isCollapsed)
  }

  // Memoize the value array to prevent unnecessary re-renders
  const contextValue = useMemo(() => [isCollapsed, handleSetIsCollapsed], [
    isCollapsed,
    handleSetIsCollapsed,
  ])

  return (
    <ContactUsCollapseContext.Provider value={contextValue}>
      {children}
    </ContactUsCollapseContext.Provider>
  )
}

ContactUsCollapseContexProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {
  ContactUsCollapseContext,
  useContactUsCollapseContext,
  ContactUsCollapseContexProvider,
}
