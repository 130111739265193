import React from 'react'
import PropTypes from 'prop-types'
import { enUS } from 'date-fns/locale'

// Emotion and MUI Providers
import { CacheProvider } from '@emotion/react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './theme'

// Context Providers
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { CookiesProvider } from 'react-cookie'
import { AuthProvider } from './utils/context/AuthContext'
import { TokenProvider } from './utils/context/TokenContext'
import { ModalProvider } from './utils/context/ModalContext'
import SearchContext from './components/Search/SearchContext'
import HarborCsThemeProvider from './utils/context/HarborCsThemeProvider'

// Error Boundary
import ErrorBoundary from './components/ErrorBoundary'

const WrapRootElement = ({ element, emotionCache }) => {
  return (
    <ErrorBoundary>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={enUS}>
            <CssBaseline />
            <CookiesProvider>
              <TokenProvider>
                <AuthProvider>
                  <ModalProvider>
                    <HarborCsThemeProvider>
                      <SearchContext.DiscoveryEngineProvider>
                        {element}
                      </SearchContext.DiscoveryEngineProvider>
                    </HarborCsThemeProvider>
                  </ModalProvider>
                </AuthProvider>
              </TokenProvider>
            </CookiesProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
    </ErrorBoundary>
  )
}

WrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
  emotionCache: PropTypes.object.isRequired,
}

export default WrapRootElement
