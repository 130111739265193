import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

// Define color constants or integrate with MUI's theme.palette if preferred
const PROGRESS_BAR_BG = '#f3f3f3'
const PROGRESS_BG = '#3498db'

// Styled Components using MUI's `styled` API
const ProgressBarContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: PROGRESS_BAR_BG,
  height: '5px',
  marginBottom: '20px',
  borderRadius: '2.5px',
  overflow: 'hidden',
  position: 'relative',
}))

const Progress = styled(Box)(({ width }) => ({
  height: '100%',
  backgroundColor: PROGRESS_BG,
  width: `${width}%`,
  transition: 'width 0.4s ease',
}))

// Main Functional Component
const ProgressBar = ({ isSubmitting, onComplete = () => {} }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    let timer
    if (isSubmitting) {
      // Start simulating progress
      timer = setInterval(() => {
        setProgress((prev) => {
          const nextProgress = prev + Math.random() * 10
          if (nextProgress >= 80) {
            clearInterval(timer)
            return 80
          }
          return nextProgress
        })
      }, 500)
    } else {
      // Complete the progress
      setProgress(100)
      if (onComplete) {
        onComplete()
      }
    }

    return () => clearInterval(timer)
  }, [isSubmitting, onComplete])

  return (
    <ProgressBarContainer aria-hidden={progress === 0}>
      <Progress width={progress} />
    </ProgressBarContainer>
  )
}

// PropTypes Validation
ProgressBar.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onComplete: PropTypes.func,
}

// Export the component as default
export default ProgressBar
