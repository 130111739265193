import React from 'react'
import PropTypes from 'prop-types'
import SideNavigationContextProvider from './utils/context/sideNavigationContext'
import { ContactUsCollapseContexProvider } from './utils/context/ContactUsCollapseContext'
import GatedContentHandler from './utils/GatedContentHandler'

const WrapPageElement = ({ element, props = {} }) => {
  const location = props.location || {}

  return (
    <GatedContentHandler location={location}>
      <SideNavigationContextProvider>
        <ContactUsCollapseContexProvider>
          {element}
        </ContactUsCollapseContexProvider>
      </SideNavigationContextProvider>
    </GatedContentHandler>
  )
}

WrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.any,
}

export default WrapPageElement
