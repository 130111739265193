import PropTypes from 'prop-types'
import React, { startTransition, useCallback, useMemo } from 'react'
import CustomRecaptcha from '../Recaptcha/Recaptcha'
import { Box } from '@mui/material' // Import MUI's Box component

const RecaptchaWrapper = ({
  formId,
  gatsbyRecaptchaKey,
  onRecaptchaChange,
  onRecaptchaExpire,
  formSubmitted,
}) => {
  const uniqueRecaptchaId = `${formId}_recaptcha`

  const handleRecaptchaChange = useCallback(
    (value) => {
      startTransition(() => {
        onRecaptchaChange(value)
      })
    },
    [onRecaptchaChange]
  )

  const handleRecaptchaExpire = useCallback(() => {
    startTransition(() => {
      onRecaptchaExpire()
    })
  }, [onRecaptchaExpire])

  // Memoize the sx object
  const containerSx = useMemo(() => ({
    marginTop: '1rem',
  }), [])

  return (
    <Box id={`recaptcha-container-${formId}`} sx={containerSx}>
      <CustomRecaptcha
        id={uniqueRecaptchaId}
        onRecaptchaChange={handleRecaptchaChange}
        onRecaptchaExpire={handleRecaptchaExpire}
        gatsbyRecaptchaKey={gatsbyRecaptchaKey}
        formSubmitted={formSubmitted}
      />
    </Box>
  )
}

RecaptchaWrapper.propTypes = {
  formId: PropTypes.string.isRequired,
  gatsbyRecaptchaKey: PropTypes.string.isRequired,
  onRecaptchaChange: PropTypes.func.isRequired,
  onRecaptchaExpire: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
}

export default RecaptchaWrapper
