import { useState, useCallback, startTransition } from 'react'

import { fetchUserRoleAPI } from '../../services/formAPI'

const useFormCustomValidation = (customValidation) => {
  const [isFetchingRole, setIsFetchingRole] = useState(false)
  const [accessRole, setAccessRole] = useState(null)
  const [error, setError] = useState(null)

  const fetchUserRole = useCallback(async (email, token) => {
    setIsFetchingRole(true)
    try {
      const data = await fetchUserRoleAPI(email, token)
      startTransition(() => {
        setAccessRole(data.role)
      })
      return data
    } catch (err) {
      if (err.name !== 'AbortError') {
        console.error('Error fetching user role:', err)
        startTransition(() => {
          setError(
            err.message || 'Failed to fetch user role. Please try again.'
          )
        })
      }
      return null
    } finally {
      setIsFetchingRole(false)
    }
  }, [])

  const validateAccessRole = useCallback(
    (role) => {
      let validationResult = { isValid: true, message: '' }
      if (customValidation === 'register') {
        if (role === 'Institutional') {
          validationResult = {
            message:
              "Our records indicate that you've already registered. Please try logging in.",
            isValid: false,
          }
        } else if (role === 'Pending Review') {
          validationResult = {
            message:
              'Our records indicate that you have a pre-existing registration request and the status is pending review. Please wait while we complete the review process.',
            isValid: false,
          }
        }
      } else if (customValidation === 'login') {
        validationResult = { isValid: false, message: '' }
        if (role === 'Institutional') {
          validationResult = { isValid: true, message: '' }
        } else if (role === 'Pending Review') {
          validationResult = {
            message:
              'Our records indicate that you have a pre-existing registration request and the status is pending review. Please wait while we complete the review process.',
            isValid: false,
          }
        } else if (role === 'Public') {
          validationResult = {
            message:
              'Your account is set for public viewing. Please register for Institutional access.',
            isValid: false,
          }
        } else {
          validationResult = {
            message:
              'The email address you entered is not associated with any account. Please verify your email and try again, or register for a new account.',
            isValid: false,
          }
        }
      }

      return validationResult
    },
    [customValidation]
  )

  return {
    isFetchingRole,
    fetchUserRole,
    validateAccessRole,
    accessRole,
    error,
  }
}

export default useFormCustomValidation
