import PropTypes from 'prop-types'
import React, {
  createContext,
  useCallback,
  useReducer,
  useMemo,
  useContext,
} from 'react'

import { useToken } from '../../utils/context/TokenContext'

// Initial state for the search context
const initialSearchState = {
  results: [],
  status: 'idle',
  searchTerms: '',
  error: null,
}

// Create the React context
const SearchContext = createContext({
  ...initialSearchState,
  search: () => {},
})

// Reducer function to manage search state
const searchReducer = (state, action) => {
  switch (action.type) {
    case 'search::start':
      return {
        ...state,
        status: 'loading',
        searchTerms: action.payload,
        error: null,
      }
    case 'search::finish':
      return { ...state, status: 'idle', results: action.payload, error: null }
    case 'search::error':
      return { ...state, status: 'idle', error: action.payload }
    default:
      return state
  }
}

// SearchProvider component
const SearchProvider = ({ searchFn, children }) => {
  const [state, dispatch] = useReducer(searchReducer, initialSearchState)

  const search = useCallback(
    async (searchTerms) => {
      dispatch({ type: 'search::start', payload: searchTerms })
      try {
        const results = await searchFn(searchTerms)
        dispatch({ type: 'search::finish', payload: results })
      } catch (error) {
        dispatch({
          type: 'search::error',
          payload: error.message || 'Unknown error',
        })
      }
    },
    [searchFn]
  )

  const contextValue = useMemo(() => ({ ...state, search }), [state, search])

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  )
}

SearchProvider.propTypes = {
  searchFn: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

// Custom hook to use the SearchContext
const useSearch = () => {
  const context = useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider')
  }
  return context
}

// DiscoveryEngineProvider Implementation
const DiscoveryEngineProvider = ({ children }) => {
  const { csrfToken, fetchCsrfToken } = useToken()

  const searchFn = useCallback(
    async (searchTerms) => {
      let token = csrfToken

      // If CSRF token is not available, fetch a new one
      if (!token) {
        try {
          token = await fetchCsrfToken()
        } catch (error) {
          console.error('Failed to fetch CSRF token:', error)
          throw new Error('Authentication error')
        }
      }

      if (!searchTerms) {
        return []
      }

      // Build the request payload as per your serverless function's requirements
      const requestBody = {
        query: searchTerms,
        pageSize: 10,
      }

      try {
        const response = await fetch('/api/gc-oauth-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-csrf-token': token,
          },
          body: JSON.stringify(requestBody),
        })

        if (!response.ok) {
          const errorData = await response.json()
          throw new Error(errorData.error || 'Discovery Engine API error')
        }

        const data = await response.json()

        // Assuming the serverless function returns a 'results' array
        const results = data.results || []

        // Preserve the original structure by keeping the entire document
        const mappedResults = results.map((result) => ({
          id: result.id,
          document: result.document,
        }))

        return mappedResults
      } catch (error) {
        console.error('Error fetching from Discovery Engine:', error)
        throw error
      }
    },
    [csrfToken, fetchCsrfToken]
  )

  return <SearchProvider searchFn={searchFn}>{children}</SearchProvider>
}

DiscoveryEngineProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

// Export the context and providers
const SearchContextExport = {
  Context: SearchContext,
  DiscoveryEngineProvider,
  useSearch,
}

export default SearchContextExport
