import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react'

// Create the context
export const SideNavigationContext = createContext()

// Custom hook to use the SideNavigationContext
export const useSideNavigationContext = () => {
  const context = useContext(SideNavigationContext)
  if (!context) {
    throw new Error(
      'useSideNavigationContext must be used within a SideNavigationContextProvider'
    )
  }
  return context
}

// Context Provider Component
const SideNavigationContextProvider = ({ children }) => {
  const [highlightedNavItem, setHighlightedNavItem] = useState(0)
  const sectionRefs = useRef([])

  // Function to create or get a section ref
  const createSectionRef = useCallback((index) => {
    if (!sectionRefs.current[index]) {
      sectionRefs.current[index] = React.createRef()
    }
    return sectionRefs.current[index]
  }, [])

  useEffect(() => {
    // Ensure this effect runs only in the client environment
    if (typeof window === 'undefined') return

    const handleScroll = () => {
      let minDistanceToTop = Infinity
      let closestIndex = -1

      sectionRefs.current.forEach((sectionRef, index) => {
        const boundingBox = sectionRef.current?.getBoundingClientRect()
        if (boundingBox) {
          const distanceToTop = Math.abs(boundingBox.top)
          if (distanceToTop < minDistanceToTop) {
            minDistanceToTop = distanceToTop
            closestIndex = index
          }
        }
      })

      if (closestIndex !== -1) {
        setHighlightedNavItem(closestIndex)
      }
    }

    window.addEventListener('scroll', handleScroll)
    // Initial check after mounting
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [sectionRefs])

  const contextValue = useMemo(
    () => ({
      highlightedNavItem,
      setHighlightedNavItem,
      createSectionRef,
      sectionRefs: sectionRefs.current,
    }),
    [highlightedNavItem, createSectionRef]
  )

  return (
    <SideNavigationContext.Provider value={contextValue}>
      {children}
    </SideNavigationContext.Provider>
  )
}

export default SideNavigationContextProvider
