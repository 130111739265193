import PropTypes from 'prop-types'
import React from 'react'

const redStyle = { color: 'red' }

const ErrorDisplay = ({ errorMessage, fieldErrors }) => {
  return (
    <div role="alert" aria-live="assertive">
      {errorMessage && <p style={redStyle}>{errorMessage}</p>}
      {Object.keys(fieldErrors).length > 0 && (
        <ul>
          {Object.entries(fieldErrors).map(([field, error]) => (
            <li key={field}>
              <a href={`#${field}`} style={redStyle}>
                {error}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

ErrorDisplay.propTypes = {
  errorMessage: PropTypes.string,
  fieldErrors: PropTypes.object.isRequired,
}

export default ErrorDisplay
