import { useState, useEffect, useCallback } from 'react'
import { fetchFormHTML } from '../../services/formAPI' // Ensure this path is correct

const formCache = new Map()
const inProgressFetches = new Map()

const fetchFormHTMLCached = (formId, token) => {
  if (formCache.has(formId)) {
    return Promise.resolve(formCache.get(formId))
  }

  if (inProgressFetches.has(formId)) {
    return inProgressFetches.get(formId)
  }

  const fetchPromise = fetchFormHTML(formId, token)
    .then((html) => {
      formCache.set(formId, html)
      inProgressFetches.delete(formId)
      return html
    })
    .catch((error) => {
      inProgressFetches.delete(formId)
      throw error
    })

  inProgressFetches.set(formId, fetchPromise)
  return fetchPromise
}

const useFormCache = (formId, token) => {
  const [formHTML, setFormHTML] = useState(formCache.get(formId) || '')
  const [hiddenFields, setHiddenFields] = useState({})
  const [loading, setLoading] = useState(!formCache.has(formId))
  const [error, setError] = useState('')

  useEffect(() => {
    if (!token) {
      setError('Token is missing.')
      setLoading(false)
      return
    }

    if (!formId) {
      setError('Form ID is missing.')
      setLoading(false)
      return
    }

    setError('')
    setLoading(!formCache.has(formId))

    if (formCache.has(formId)) {
      const cachedHTML = formCache.get(formId)
      setFormHTML(cachedHTML)
      const parser = new DOMParser()
      const doc = parser.parseFromString(cachedHTML, 'text/html')
      const hiddenInputs = doc.querySelectorAll('input[type="hidden"]')
      const hiddenValues = {}

      hiddenInputs.forEach((field) => {
        const id = field.id || field.name
        if (id) {
          hiddenValues[id] = field.value || ''
        }
      })

      setHiddenFields(hiddenValues)
      setLoading(false)
      return
    }

    let isCancelled = false

    const fetchAndCacheFormHTML = async () => {
      try {
        const fetchedHTML = await fetchFormHTMLCached(formId, token)
        if (isCancelled) return

        setFormHTML(fetchedHTML)
        const parser = new DOMParser()
        const doc = parser.parseFromString(fetchedHTML, 'text/html')
        const hiddenInputs = doc.querySelectorAll('input[type="hidden"]')
        const hiddenValues = {}

        hiddenInputs.forEach((field) => {
          const id = field.id || field.name
          if (id) {
            hiddenValues[id] = field.value || ''
          }
        })

        setHiddenFields(hiddenValues)
      } catch (err) {
        if (!isCancelled) {
          setError(err.message || 'Error fetching form.')
        }
      } finally {
        if (!isCancelled) {
          setLoading(false)
        }
      }
    }

    fetchAndCacheFormHTML()

    return () => {
      isCancelled = true
    }
  }, [formId, token])

  return { formHTML, hiddenFields, loading, error }
}

export default useFormCache
