import React from 'react'
import PropTypes from 'prop-types'
import FormComponent from './FormComponent'
import ErrorBoundary from './ErrorBoundary'
import { useToken } from '../../utils/context/TokenContext'

const FormAssemblyForm = React.memo(
  ({ formId, customValidation, isModal, ctaText, dataLocation }) => {
    const { csrfToken, fetchCsrfToken } = useToken()
    if (!csrfToken) {
      try {
        fetchCsrfToken()
      } catch (error) {
        console.error('Failed to fetch CSRF token:', error)
      }
    }

    return (
      <ErrorBoundary>
        <FormComponent
          formId={formId}
          customValidation={customValidation}
          isModal={isModal}
          ctaText={ctaText}
          dataLocation={dataLocation}
          formCSRF={csrfToken}
        />
      </ErrorBoundary>
    )
  }
)

FormAssemblyForm.displayName = "FormAssemblyForm"

FormAssemblyForm.propTypes = {
  formId: PropTypes.number.isRequired,
  customValidation: PropTypes.string,
  isModal: PropTypes.bool,
  ctaText: PropTypes.string,
  dataLocation: PropTypes.any,
}

export default FormAssemblyForm