import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useTransition,
} from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { useCookies } from 'react-cookie'
import { AuthContext } from './context/AuthContext'
import { ONE_HOUR_COOKIE_EXPIRE, USER_EXPERIENCES } from './constants'
import FormModal from '../components/Modal/FormModal'
import { useToken } from './context/TokenContext'

const isWindowDefined = () => typeof window !== 'undefined'

const skipLoginPaths = ['/contact-us/institutional']

const GatedContentHandler = ({ children, location }) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [isPending, startTransition] = useTransition()
  const [harborCookieFetched, setHarborCookieFetched] = useState(false)

  const data = useStaticQuery(graphql`
    query GatedContentHandlerQuery {
      allContentstackContactUsLoggedIn(filter: { user_experience: { experience: { eq: "institutional-only" } } }) {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackInsightArticle {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackLandingPage(filter: { user_experience: { experience: { eq: "institutional-only" } } }) {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackFaForm(filter: { form_id: { in: [29, 30] } }) {
        nodes {
          form_id
          form_cta_text
          custom_validations
        }
      }
    }
  `)

  if (!data) {
    console.warn('GatedContentHandler query returned no data.')
  }

  const [cookies] = useCookies(['harborCookie'])
  const { harborCookie } = cookies

  const authCtx = useContext(AuthContext)
  const { csrfToken, fetchCsrfToken } = useToken()

  const { pathname, search, state } = location || {}
  const { data: dataLocation } = state || {}

  const trimmedPathname = pathname?.endsWith('/')
    ? pathname.slice(0, -1)
    : pathname || ''

  const isInsight = useMemo(() => trimmedPathname.startsWith('/insights/'), [trimmedPathname])

  const privatePages = useMemo(() => {
    // Aggregate nodes only from sources that include user_experience
    const routes = [
      ...(data.allContentstackContactUsLoggedIn?.nodes || []),
      ...(data.allContentstackInsightArticle?.nodes || []),
      ...(data.allContentstackLandingPage?.nodes || []),
    ]

    // Filter routes based on user_experience
    return routes
      .filter((route) => route.user_experience?.experience === USER_EXPERIENCES.INSTITUTIONAL_ONLY)
      .map((route) => route.url)
  }, [data])

  const isPrivatePage = privatePages.includes(trimmedPathname)

  const queryParams = useMemo(
    () => (isWindowDefined() ? new URLSearchParams(search) : new URLSearchParams()),
    [search]
  )

  const modalKey = useMemo(() => queryParams.get('form'), [queryParams])

  const faForms = data?.allContentstackFaForm?.nodes || []
  const formData = useMemo(() => {
    const mapping = {}
    faForms.forEach((form) => {
      if (form.form_id === 29) {
        mapping.login = {
          formId: form.form_id,
          formCta: form.form_cta_text,
          customValidation: form.custom_validations,
        }
      } else if (form.form_id === 30) {
        mapping.register = {
          formId: form.form_id,
          formCta: form.form_cta_text,
          customValidation: form.custom_validations,
        }
      }
    })
    return mapping
  }, [faForms])

  const formId = formData[modalKey]?.formId
  const formCta = formData[modalKey]?.formCta
  const customValidation = formData[modalKey]?.customValidation

  const handleModalClose = useCallback(() => {
    startTransition(() => setModalOpen(false))
    if (isWindowDefined()) {
      const updatedQueryParams = new URLSearchParams(search)
      updatedQueryParams.delete('form')
      const newSearch = updatedQueryParams.toString()
      const newUrl = `${pathname}${newSearch ? `?${newSearch}` : ''}`
      navigate(newUrl, { replace: true })
    }
  }, [pathname, search, startTransition])

  useEffect(() => {
    if (isWindowDefined()) {
      setIsClient(true)
    }
  }, [])

  useEffect(() => {
    if (formId) {
      startTransition(() => setModalOpen(true))
    } else {
      startTransition(() => setModalOpen(false))
    }
  }, [formId])

  useEffect(() => {
    const fetchHarborCookie = async () => {
      if (!csrfToken) {
        console.warn('CSRF token is missing')
        return
      }

      try {
        const res = await fetch('/api/harbor-cookie', {
          headers: {
            'Content-Type': 'application/json',
            'x-csrf-token': csrfToken,
          },
          credentials: 'same-origin',
        })

        if (!res.ok) {
          throw new Error('Failed to fetch harbor-cookie')
        }
        authCtx.actions.tryLogin()
        setHarborCookieFetched(true)
      } catch (error) {
        console.error('Error fetching harbor-cookie:', error)
        setHarborCookieFetched(true)
      }
    }

    if (
      !harborCookieFetched &&
      isPrivatePage &&
      isClient &&
      (!harborCookie || harborCookie.split('|').some((val) => val === 'null')) &&
      !skipLoginPaths.some((path) => trimmedPathname.startsWith(path))
    ) {
      fetchHarborCookie()
    }
  }, [
    trimmedPathname,
    isPrivatePage,
    harborCookie,
    authCtx.actions,
    csrfToken,
    harborCookieFetched,
    isClient,
  ])

  if (!isClient) {
    return 
  }

  return (
    <>
      {children}
      {isModalOpen && formId && formCta && (
        <FormModal
          onClose={handleModalClose}
          isOpen={isModalOpen}
          formId={formId}
          customValidation={customValidation}
          isModal
          ctaText={formCta}
          dataLocation={dataLocation}
        />
      )}
    </>
  )
}

export default React.memo(GatedContentHandler)
