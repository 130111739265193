import { Box } from '@mui/material'
import { keyframes, styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React from 'react'

// Define color constants or integrate them with MUI's theme.palette if preferred
const LIGHT_GRAY = '#f3f3f3'
const BLUE = '#3498db'

// Define the rotation animation using MUI's keyframes
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

// Styled Components using MUI's `styled` API
const SpinnerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px', // Adjust based on form container height
}))

const SpinnerElement = styled(Box)(({ theme }) => ({
  border: `6px solid ${LIGHT_GRAY}`, // Light gray
  borderTop: `6px solid ${BLUE}`, // Blue
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  animation: `${rotate} 1s linear infinite`,
}))

// Main Functional Component
const Spinner = ({ className = '' }) => (
  <SpinnerWrapper className={className} aria-label="Loading">
    <SpinnerElement aria-hidden="true" />
  </SpinnerWrapper>
)

// PropTypes Validation
Spinner.propTypes = {
  className: PropTypes.string,
}

// Export the component as default
export default Spinner
