import { useState, useCallback, useMemo } from 'react'

import validZipCodesData from '../zipcodes.json'

const useFormValidation = (formHTML, formFields, formValues) => {
  const [fieldErrors, setFieldErrors] = useState({})

  const validZipCodesSet = useMemo(
    () =>
      new Set(
        validZipCodesData.map((item) => String(item.zip_code).padStart(5, '0'))
      ),
    []
  )

  const freeEmailDomains = useMemo(
    () => [
      'gmail.com',
      'yahoo.com',
      'ymail.com',
      'rocketmail.com',
      'outlook.com',
      'hotmail.com',
      'live.com',
      'msn.com',
      'aol.com',
      'protonmail.com',
      'icloud.com',
      'me.com',
      'mac.com',
      'zoho.com',
      'mail.com',
      'gmx.com',
      'gmx.de',
      'web.de',
      'yandex.com',
      'yandex.ru',
      'tutanota.com',
      'fastmail.com',
      'rediffmail.com',
      'lycos.com',
      'juno.com',
      'naver.com',
      'daum.net',
      'o2.co.uk',
      'virginmedia.com',
      'blueyonder.co.uk',
      'talktalk.net',
      'btinternet.com',
      'wanadoo.fr',
      'orange.fr',
      'free.fr',
      'sfr.fr',
      'laposte.net',
      'libero.it',
      'tiscali.it',
      'virgilio.it',
      'alice.it',
      'tin.it',
      'seznam.cz',
      'centrum.cz',
      'post.cz',
      'volny.cz',
      'atlas.cz',
      'optonline.net',
      'earthlink.net',
      'comcast.net',
      'verizon.net',
      'att.net',
      'cox.net',
      'charter.net',
      'sbcglobal.net',
    ],
    []
  )

  const validationRules = useMemo(() => {
    const rules = {}

    // Process formFields if provided
    if (formFields && formFields.length > 0) {
      formFields.forEach((field) => {
        const { type, props } = field
        const { id, label, required, minLength, maxLength, freevalidation } =
          props
        if (!id) {
          return
        }

        if (!rules[id]) {
          rules[id] = {}
        }

        const labelLower = label ? label.toLowerCase() : ''

        // **Zip Code Validation**
        if (
          ['zip code', 'postal code', 'zipcode'].some((sub) =>
            labelLower.includes(sub)
          )
        ) {
          rules[id].regex = /^\d{5}(-\d{4})?$/
          rules[id].errorMessage = 'Invalid zip code format.'
          rules[id].isZipCode = true
        }

        // **E-mail Validation**
        if (['e-mail', 'email'].some((sub) => labelLower.includes(sub))) {
          rules[id].regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          rules[id].errorMessage = 'Invalid email format.'
          rules[id].isEmail = true
          if (freevalidation === 'false') {
            rules[id].freevalidation = 'false'
          } else {
            rules[id].freevalidation = 'true'
          }
        }

        // **Phone Number Validation**
        if (['phone', 'telephone'].some((sub) => labelLower.includes(sub))) {
          rules[id].regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
          rules[id].errorMessage = 'Invalid phone number format.'
        }

        // **Required Check**
        if (required) {
          rules[id].required = true
          rules[id].errorMessage =
            rules[id].errorMessage || 'This field is required.'
        }

        // **Length Checks**
        if (minLength) {
          rules[id].minLength = parseInt(minLength, 10)
        }

        if (maxLength) {
          rules[id].maxLength = parseInt(maxLength, 10)
        }
      })
    }

    // Process formHTML if provided
    if (formHTML) {
      if (typeof window === 'undefined') {
        return rules
      }

      try {
        const parser = new DOMParser()
        const doc = parser.parseFromString(formHTML, 'text/html')
        const fields = doc.querySelectorAll('input, select, textarea')

        fields.forEach((field) => {
          const fieldId = field.id
          if (!fieldId) {
            return
          }

          if (!rules[fieldId]) {
            rules[fieldId] = {}
          }

          const title = field.getAttribute('title') || ''
          const labelLower = title.toLowerCase()

          // **Zip Code Validation**
          if (
            ['zip code', 'postal code', 'zipcode'].some((sub) =>
              labelLower.includes(sub)
            )
          ) {
            rules[fieldId].regex = /^\d{5}(-\d{4})?$/
            rules[fieldId].errorMessage = 'Invalid zip code format.'
            rules[fieldId].isZipCode = true
          }

          // **E-mail Validation**
          if (['e-mail', 'email'].some((sub) => labelLower.includes(sub))) {
            rules[fieldId].regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            rules[fieldId].errorMessage = 'Invalid email format.'
            rules[fieldId].isEmail = true
            // Assuming freeValidation is true by default
            rules[fieldId].freevalidation = 'true'
          }

          // **Phone Number Validation**
          if (['phone', 'telephone'].some((sub) => labelLower.includes(sub))) {
            rules[fieldId].regex =
              /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
            rules[fieldId].errorMessage = 'Invalid phone number format.'
          }

          // **Required Check**
          let isRequired =
            field.hasAttribute('required') ||
            field.getAttribute('aria-required') === 'true'

          if (!isRequired) {
            const parentId = field.getAttribute('data-tfa-parent-id')
            if (parentId) {
              const parentElement = doc.getElementById(parentId)
              if (
                parentElement &&
                parentElement.classList.contains('required')
              ) {
                isRequired = true
              }
            }
          }

          if (isRequired) {
            rules[fieldId].required = true
            rules[fieldId].errorMessage =
              rules[fieldId].errorMessage || 'This field is required.'
          }

          const minLengthAttr = field.getAttribute('minlength')
          if (minLengthAttr) {
            rules[fieldId].minLength = parseInt(minLengthAttr, 10)
          }

          const maxLengthAttr = field.getAttribute('maxlength')
          if (maxLengthAttr) {
            rules[fieldId].maxLength = parseInt(maxLengthAttr, 10)
          }
        })
      } catch (error) {
        console.error('Error parsing formHTML:', error)
      }
    }

    return rules
  }, [formFields, formHTML])

  const validateField = useCallback(
    (fieldId, fieldValue) => {
      const rules = validationRules[fieldId]
      if (!rules) {
        return { hasError: false, error: '' }
      }

      const value = fieldValue?.toString().trim() || ''
      let error = ''

      // Check if the field is required and empty
      if (rules.required && value === '') {
        error = 'This field is required.'
      }
      // Check for minimum length
      else if (rules.minLength && value.length < rules.minLength) {
        error = `Minimum length is ${rules.minLength}.`
      }
      // Check for maximum length
      else if (rules.maxLength && value.length > rules.maxLength) {
        error = `Maximum length is ${rules.maxLength}.`
      }
      // Apply regex validation only if the field has a value
      else if (rules.regex && value !== '') {
        if (!rules.regex.test(value)) {
          error = rules.errorMessage || 'Invalid format.'
        }
      }

      // Additional Zip Code Existence Check
      if (rules.isZipCode && value !== '' && !error) {
        const zipCode = value.split('-')[0] // Extract the 5-digit part if ZIP+4
        if (!validZipCodesSet.has(zipCode)) {
          error = 'Invalid zip code.'
        }
      }

      // Additional Email Domain Check
      if (
        rules.isEmail &&
        value !== '' &&
        !error &&
        rules.freevalidation === 'true'
      ) {
        const domain = value.split('@')[1]?.toLowerCase()
        if (domain && freeEmailDomains.includes(domain)) {
          error = `Emails from '${domain}' are not allowed.`
        }
      }

      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldId]: error,
      }))

      return { hasError: !!error, error }
    },
    [validationRules, validZipCodesSet, freeEmailDomains]
  )

  const validateAllFields = useCallback(() => {
    const newFieldErrors = {}
    let hasError = false

    for (const fieldId in validationRules) {
      const rules = validationRules[fieldId]
      if (!rules) {
        continue
      }

      const value = formValues[fieldId]?.toString().trim() || ''
      let error = ''

      if (rules.required && value === '') {
        error = 'This field is required.'
      } else if (rules.minLength && value.length < rules.minLength) {
        error = `Minimum length is ${rules.minLength}.`
      } else if (rules.maxLength && value.length > rules.maxLength) {
        error = `Maximum length is ${rules.maxLength}.`
      } else if (rules.regex && value !== '') {
        if (!rules.regex.test(value)) {
          error = rules.errorMessage || 'Invalid format.'
        }
      }

      // Additional Zip Code Existence Check
      if (rules.isZipCode && value !== '' && !error) {
        const zipCode = value.split('-')[0]
        if (!validZipCodesSet.has(zipCode)) {
          error = 'Invalid zip code.'
        }
      }

      // Additional Email Domain Check
      if (
        rules.isEmail &&
        value !== '' &&
        !error &&
        rules.freevalidation === 'true'
      ) {
        const domain = value.split('@')[1]?.toLowerCase()
        if (domain && freeEmailDomains.includes(domain)) {
          error = `Emails from '${domain}' are not allowed.`
        }
      }

      if (error) {
        newFieldErrors[fieldId] = error
        hasError = true
      }
    }

    setFieldErrors(newFieldErrors)
    return { hasError, newFieldErrors }
  }, [formValues, validationRules, validZipCodesSet, freeEmailDomains])

  const clearErrors = useCallback(() => {
    setFieldErrors({})
  }, [])

  const hasErrors = useMemo(
    () => Object.keys(fieldErrors).length > 0,
    [fieldErrors]
  )

  return {
    fieldErrors,
    validateField,
    validateAllFields,
    setFieldErrors,
    clearErrors,
    hasErrors,
    validationRules,
  }
}

export default useFormValidation
