import { Box, IconButton } from '@mui/material'
import { styled, keyframes } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import ReactDOM from 'react-dom'

import { BLUE_BASE } from '../../theme/variables/colors'
import { hexToRgba } from '../../utils/functions'
import FormAssemblyForm from '../Form/FormAssemblyForm'

const navyColor = hexToRgba(BLUE_BASE, 0.8)

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const OuterModal = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  maxWidth: '80%',
  minWidth: '40%',
  padding: '20px',
  position: 'fixed',
  zIndex: 9999,
  overflow: 'hidden',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFFFFF',
  boxShadow: `0 4px 8px ${navyColor}`,
  borderRadius: '20px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    maxHeight: '100vh',
    height: '100%',
    width: '100%',
  },
}))

const HeaderModal = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  height: '40px',
}))

const BodyModal = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'hidden',
  backgroundColor: '#FFFFFF',
  maxHeight: '75vh',
  height: 'fit-content',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    maxHeight: '90vh',
  },
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'relative',
  width: '30px',
  height: '30px',
  color: navyColor,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'transform 0.4s ease-in-out',
  '&.spin': {
    animation: `${rotate} 0.4s ease-in-out`,
  },
}))

const Line = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '4px',
  backgroundColor: 'black',
  transition: 'transform 0.4s ease-in-out',
}))

const LineBefore = styled(Line)(({ theme }) => ({
  transform: 'rotate(45deg)',
}))

const LineAfter = styled(Line)(({ theme }) => ({
  transform: 'rotate(-45deg)',
}))

const FormModalComponent = ({
  onClose,
  isOpen,
  formId,
  customValidation = null,
  isModal = true,
  ctaText = 'Submit',
  dataLocation = '',
  ...props
}) => {
  const [isSpinning, setIsSpinning] = useState(false)

  const handleCloseClick = useCallback(() => {
    setIsSpinning(true)
    setTimeout(() => {
      setIsSpinning(false)
      onClose()
    }, 400)
  }, [onClose])

  if (!formId || !isOpen) {
    return null
  }

  // Ensure that document access is client-side only
  if (typeof document === 'undefined') {
    console.warn('[FormModalComponent]: Attempted to render modal during SSR.')
    return null
  }

  return ReactDOM.createPortal(
    <OuterModal
      role="dialog"
      aria-modal="true"
      aria-labelledby="form-modal-title"
      {...props}
    >
      <HeaderModal>
        <CloseButton
          onClick={handleCloseClick}
          className={isSpinning ? 'spin' : ''}
          aria-label="Close modal"
        >
          <LineBefore />
          <LineAfter />
        </CloseButton>
      </HeaderModal>
      <BodyModal>
        <FormAssemblyForm
          formId={formId}
          customValidation={customValidation}
          isModal={isModal}
          ctaText={ctaText}
          dataLocation={dataLocation}
        />
      </BodyModal>
    </OuterModal>,
    document.getElementById('modal-root')
  )
}

FormModalComponent.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  customValidation: PropTypes.any,
  isModal: PropTypes.bool,
  ctaText: PropTypes.string,
  dataLocation: PropTypes.any,
}

FormModalComponent.displayName = 'FormModalComponent'

const FormModal = React.memo(FormModalComponent)

export default FormModal
